import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'slide' ];

  initialize() {
    super.initialize();
    this.duration = 300;
  }

  connect(){

  }

  slideUp(e) {
    this.slideTarget.style.transitionProperty = 'height, margin, padding';
    this.slideTarget.style.transitionDuration = this.duration + 'ms';
    this.slideTarget.style.boxSizing = 'border-box';
    this.slideTarget.style.height = this.slideTarget.offsetHeight + 'px';
    this.slideTarget.offsetHeight;
    this.slideTarget.style.overflow = 'hidden';
    this.slideTarget.style.height = 0;
    this.slideTarget.style.paddingTop = 0;
    this.slideTarget.style.paddingBottom = 0;
    this.slideTarget.style.marginTop = 0;
    this.slideTarget.style.marginBottom = 0;
    window.setTimeout( () => {
      this.slideTarget.style.display = 'none';
      this.slideTarget.style.removeProperty('height');
      this.slideTarget.style.removeProperty('padding-top');
      this.slideTarget.style.removeProperty('padding-bottom');
      this.slideTarget.style.removeProperty('margin-top');
      this.slideTarget.style.removeProperty('margin-bottom');
      this.slideTarget.style.removeProperty('overflow');
      this.slideTarget.style.removeProperty('transition-duration');
      this.slideTarget.style.removeProperty('transition-property');
    }, this.duration);
  }

  slideDown(e) {
    this.slideTarget.style.removeProperty('display');
    let display = window.getComputedStyle(this.slideTarget).display;

    if (display === 'none')
      display = 'block';

    this.slideTarget.style.display = display;
    let height = this.slideTarget.offsetHeight;
    this.slideTarget.style.overflow = 'hidden';
    this.slideTarget.style.height = 0;
    this.slideTarget.style.paddingTop = 0;
    this.slideTarget.style.paddingBottom = 0;
    this.slideTarget.style.marginTop = 0;
    this.slideTarget.style.marginBottom = 0;
    this.slideTarget.offsetHeight;
    this.slideTarget.style.boxSizing = 'border-box';
    this.slideTarget.style.transitionProperty = "height, margin, padding";
    this.slideTarget.style.transitionDuration = this.duration + 'ms';
    this.slideTarget.style.height = height + 'px';
    this.slideTarget.style.removeProperty('padding-top');
    this.slideTarget.style.removeProperty('padding-bottom');
    this.slideTarget.style.removeProperty('margin-top');
    this.slideTarget.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
      this.slideTarget.style.removeProperty('height');
      this.slideTarget.style.removeProperty('overflow');
      this.slideTarget.style.removeProperty('transition-duration');
      this.slideTarget.style.removeProperty('transition-property');
    }, this.duration);
  }

  slideToggle(e) {
    if (window.getComputedStyle(this.slideTarget).display === 'none') {
      return this.slideDown();
    } else {
      return this.slideUp();
    }
  }
}
