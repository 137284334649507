import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'group', 'colours', 'submit', 'back', 'radio' ];

  connect(){
    this.hideBeltGroups();
    this.disableSubmit();
    const beltInput = document.querySelector('input[name="student[belt]"]:checked')

    if (beltInput?.value) {
      const [colour, grade] = beltInput.value.split('.').map(Number);
      const beltGroupIndex = colour - 1;
      this.selectColour(beltGroupIndex);
      this.selectGrade(beltGroupIndex, grade);
    }
  }

  proxiedSelectColour(e){
    this.selectColour(e.currentTarget.dataset.beltIndex)
  }

  selectColour(beltGroupIndex){
    this.hideBeltGroups();
    this.groupTargets[beltGroupIndex].classList.remove('hidden');
    this.coloursTarget.classList.add('hidden');
    this.backTarget.classList.remove('invisible');
    document.getElementById('previous_step_link').classList.add('hidden');
  }

  proxiedSelectGrade(e){
    this.selectGrade(e.currentTarget.dataset.beltIndex, e.currentTarget.dataset.gradeIndex);
  }

  selectGrade(beltGroupIndex, gradeIndex) {
    this.element.querySelectorAll('.belt-picker__belt-option').forEach(function(element){
      element.classList.add('opacity-50');
    });
    this.groupTargets[beltGroupIndex].querySelectorAll('.belt-picker__belt-option')[gradeIndex].classList.remove('opacity-50');
    this.enableSubmit();
  }

  hideBeltGroups() {
    this.groupTargets.forEach(function(element){
      element.classList.add('hidden');
    });
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add('--disabled');
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
    this.submitTarget.classList.remove('--disabled');
  }

  backToBeltSelection(){
    this.hideBeltGroups();
    this.coloursTarget.classList.remove('hidden');
    this.backTarget.classList.add('invisible');
    document.getElementById('previous_step_link').classList.remove('hidden');
    this.resetSelections();
  }

  resetSelections() {
    this.disableSubmit();
    this.element.querySelectorAll('.belt-picker__belt-option').forEach(function(element){
      element.classList.remove('opacity-50');
    });
    this.radioTargets.forEach(function(radio){
      radio.checked = false;
    });
  }
}
