import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'submit', 'input' ];

  connect(){
    this.onChange();
  }

  onChange() {
    const valid = document.querySelector('input[name="student[gender]"]:checked') !== null;
    ( valid ) ? this.enableSubmit() : this.disableSubmit();
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add('--disabled');
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
    this.submitTarget.classList.remove('--disabled');
  }
}
